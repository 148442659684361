.header-container {
  z-index: 10;
  border: 0px solid saddlebrown;
  position: fixed;
  width: 100%;
  min-height: 70px;
  background-color: rgba(255, 255, 255, 0.945);
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 0 0px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 70px;
  min-width: 100px;
  margin: 0 30px;
}
.logo-container img {
  display: block;
  height: 90%;
  width: auto;
  margin: auto;
}

.heading-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  flex-grow: 1;
}
.heading-container > span {
  margin: 0;
  font-size: 40px;
  text-align: center;
}
#title-heading {
  font-weight: 700;
  margin: 5px;
}
#tagline {
  margin: 5px;
  font-size: 25px;
  font-weight: 400;
}

.languages-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0277bd;
  border-radius: 10px;
  min-width: 100px;
  margin: 0 30px;
}
.languages-container > span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 946px) {
  .header-container {
    position: relative;
    justify-content: space-between;
    align-items: center;
  }
  .heading-container {
    justify-content: center;

    flex-grow: 0;
  }
  #tagline {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 926px) {
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 640px) {
  .logo-container {
    height: 60px;
    min-width: 80px;
    margin: 0 30px;
  }

  .heading-container > span {
    font-size: 30px;
  }
  #tagline {
    font-size: 20px;
  }
  .languages-container {
    min-width: 80px;
  }
}

@media only screen and (max-width: 582px) {
  .logo-container {
    height: 50px;
    min-width: 60px;
    margin: 0 30px;
  }
  .heading-container > span {
    font-size: 25px;
  }
  #tagline {
    font-size: 18px;
  }
  .languages-container {
    min-width: 60px;
  }

  .languages-container > span {
    font-size: 15px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 473px) {
  .logo-container {
    height: 50px;
    min-width: 50px;
    margin: 0 30px;
  }
  .heading-container > span {
    font-size: 25px;
  }
  #tagline {
    font-size: 16px;
  }
  .languages-container {
    min-width: 40px;
  }

  .languages-container > span {
    font-size: 15px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 383px) {
  .logo-container {
    height: 40px;
    min-width: 40px;
    margin: 0 20px;
  }
  .heading-container > span {
    font-size: 23px;
  }
  #tagline {
    font-size: 16px;
  }
  .languages-container {
    min-width: 40px;
    margin: 0 20px;
  }

  .languages-container > span {
    font-size: 15px;
    font-weight: 400;
  }
}
