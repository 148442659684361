.admin-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 70px;
  flex-grow: 1;
}
.admin-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 400px;
}
.admin-left {
  background-color: #0277bd;
  color: white;
}

.admin-login {
  border: 0px solid rgb(255, 106, 0);
  display: flex;
  flex-direction: column;
}

.admin-login input {
  min-width: 400px;
  padding: 15px;
  margin: 10px 0 10px 0;
  border: 2px solid #0277bd;
  border-radius: 10px;
}

.admin-login button {
  padding: 15px 50px 15px 50px;
  margin: 10px 0 10px 0;
  border: 0px;
  border-radius: 10px;
  background-color: #0277bd;
  box-shadow: 0px 4px 4px #0000002c;
  color: white;
}

.error-box {
  background-color: rgb(216, 155, 165);
  text-align: center;
  border-radius: 5px;
}
.error-box p {
  margin: 0px;
}

/* tasks Styles */

.tasks-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.tasks-container {
  border: 0px solid green;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 70px;
  flex-grow: 1;
}

.tasks-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 400px;
}

.tasks-wrapper-left {
  background-color: #0277bd;
  color: white;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  text-align: center;
}
.tasks-container > p {
  font-size: 35px;
  margin-bottom: 15px;
}

.task-button {
  background-color: #0277bd;
  color: white;
  width: 100%;
  margin: 15px 0;
  font-size: 20px;
  padding: 20px 0;
  border: 0px;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.task-button:hover {
  box-shadow: 0 0 10px 0 #0277bd;
}

@media only screen and (max-width: 946px) {
  .admin-container {
    padding-top: 0px;
  }
  .tasks-container {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 890px) {
  .admin-container > div {
    width: 100%;
    min-width: 200px;
  }
  .admin-login {
    width: 40%;
  }
  .admin-login input {
    min-width: 50%;
  }
  .tasks-container > div {
    width: 100%;
    min-width: 200px;
  }
}

@media only screen and (max-width: 450px) {
  .admin-login {
    width: 90%;
  }
  .admin-login input {
    min-width: 80%;
  }
}
