.patients-main-container {
  border: 0px solid saddlebrown;
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
}

.patients-left-container {
  background-color: #0277bd;
  display: flex;
  flex-direction: column;
  width: 17%;
  max-height: 100vh;
  top: 70px;
  bottom: 0px;
  position: fixed;
  overflow: auto;
}

.search-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addpatientButton {
  width: 90%;
  border: 0px;
  margin: 20px 0;
  padding: 5px 0;
  font-size: 20px;
  border-radius: 5px;
  background-color: #005689;
  color: white;
}

.toggle {
  background-color: #013d5f;
  color: white;
  text-align: center;
  display: none;
}

.patients-list-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.patient-display-list {
  display: flex;
  justify-content: center;
}

.patientBtn {
  font-size: 17px;
  padding: 5px 0;
  width: 100%;
  margin: 5px;
  border: 0px solid sandybrown;
  background-color: #0277bd;
  color: white;
}

.patientBtn:hover {
  background-color: #eeeeee;
  color: black;
}

.active {
  font-size: 17px;
  padding: 5px 0;
  width: 100%;
  margin: 5px 0;
  border: 0px solid sandybrown;
  background-color: #eeeeee;
  color: black;
}

.patients-right-container {
  margin-left: 17%;
  border: 0px solid greenyellow;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 83%;
  min-height: 100vh;
}

.add-patient-container {
  border: 0px solid gold;
  width: 100%;
  flex-grow: 1;
}

.addPatientHeadingContainer {
  margin: 10px 10px 10px 20px;
}

.add-patient-container form {
  display: flex;
  flex-direction: column;
  border: 0px solid saddlebrown;
  min-width: 350px;
  max-width: 500px;
  margin: 10px 10px 10px 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
}

.input-container textarea {
  padding: 0;
}

#relationship {
  display: none;
}

.input-button-container {
  margin-top: 25px;
}

.PatientDashboardContainer {
  flex-grow: 1;
}

.tabs-container {
  display: flex;
  justify-content: left;
  position: sticky;
  margin: 0 20px;
  top: 70px;
  z-index: 11;
}

.show-selected-patient {
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

.show-selected-patient p {
  display: inline;
  border-bottom: 5px solid #67c2a7;
}

.tabs-container > button {
  background-color: white;
  border: 0px;
  color: black;
  font-size: 17px;
  padding: 10px 20px;
  border-bottom: 1px solid black;
}

.tabs-container > button:hover {
  background-color: #005689;
  color: white;
}

.tabs-content-container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
}

.selectedPatientContainer {
  margin: 10px 20px;
  border-bottom: 1px solid black;
}

.patient-exercises-container {
  min-width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*
Recruitment style
*/

.show-patient-recruitment-details-wrapper {
  border: 0px solid saddlebrown;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.show-patient-recruitment-details {
  background-color: white;
  border: 0px solid gold;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  min-width: 350px;
  max-width: 500px;
  margin: 0 20px 0 20px;
}
.detailsControls {
  display: flex;
  flex-direction: column;
}
.update-save {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

#update-button {
  display: block;
}

#save-button {
  display: none;
}

#cancel-button {
  display: none;
}

.print-button {
  padding: 15px 50px 15px 50px;
  margin: 10px;
  border: 0px;
  border-radius: 10px;
  background-color: #0277bd;
  box-shadow: 0px 4px 4px #0000002c;
  color: white;
}

/* 
Recommendations CSS 
*/
.recommendations-container-parent {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.recommendations-set-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  width: 95%;
  max-width: 1500px;
}

.recommendations-set-controls-and-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #53535356;
}
.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-click-surface {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#content-sets {
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px;
}
.recommendations-inner-wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dates-container {
  display: flex;
  flex-wrap: wrap;
}

.dates-container .input-container {
  margin: 0 10px;
}

.conditions-container {
  width: 100%;
}

.recommendations-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;
  max-width: 1500px;
}

.recommendations-box-container {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(41, 41, 41, 0.3) 0px 1px 2px 0px,
    rgba(41, 41, 41, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  width: 45%;
  min-width: 500px;
}

.head {
  padding: 5px;
  font-size: 20px;
  font-weight: 600;
}

.recommendations-boxes {
  background-color: #ffffff;
  width: 100%;
  border-bottom: 5px solid #f3f5f9;
}

.toggleview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.toggleview > span {
  margin: 0 10px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.content {
  background-color: white;
  border-top: 2px solid #f3f5f9;
  display: none;
  z-index: 10;
}

.options {
  width: 100%;
  border: 0px;
  margin: 5px 0;
  padding: 0px 0;
  font-weight: 400;
}

.options p {
  padding: 7px;
}

.options:hover {
  background-color: #0277bd;
  cursor: pointer;
  color: white;
}
.checkboxes {
  margin: 0 10px;
}

.options > label {
  padding: 6px 0;
  display: block;
}

.assigned-content {
  border-top: 2px solid #f3f5f9;
  display: block;
}

#availableHazards {
  color: rgb(148, 6, 6);
}
#availableSuggestions {
  color: rgb(2, 104, 2);
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

#add {
  display: block;
}

#remove {
  display: block;
}

/* 
Exercise style
*/

.patient-exercises-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.exercises-main-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;
  max-width: 1500px;
}
.exercise-wrapper {
  margin: 20px;
  width: 45%;
  min-width: 500px;
}
.exercise-details-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}
.exercise-details-container > label {
  border: 0px solid gray;
  padding: 6px;
  display: block;
  width: 100%;
}

.reps {
  display: flex;
  align-items: center;
}
.reps > input {
  background-color: aliceblue;
  font-size: 18px;
  font-weight: 400;
  border: 0px;
  width: 50px;
  margin: 0 15px 0 5px;
  text-align: center;
}
.reps > input:disabled {
  background-color: #a8a8a8;
}
.reps > label {
  width: 50px;
  margin: 0 5px 0 0;
}

.duration {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.duration > input {
  background-color: aliceblue;
  font-size: 18px;
  font-weight: 400;
  border: 0px;
  width: 50px;
  margin: 0 15px 0 5px;
  text-align: center;
}
.duration > input:disabled {
  background-color: #a8a8a8;
}
.duration > label {
  width: 50px;
  margin: 0 5px 0 0;
}

/*
Diary Style
*/
table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

/*
Score Style
*/
.score-wrapper {
  display: flex;
  flex-direction: column;
}
.totalScore {
  background-color: #7db8a6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}
.totalScore > p {
  display: inline-block;
  font-weight: 600;
}
.scoreTabs > button {
  background-color: white;
  border: 0px;
  color: black;
  font-size: 17px;
  padding: 10px 20px;
  border-bottom: 1px solid black;
}
.scoreBox {
  display: none;
  flex-direction: column;
  margin-top: 5px;
}
.scoreWeekPoints {
  margin-top: 20px;
}
.weekHeading {
  margin-bottom: 2px;
}
.goalScoreDetails {
  display: block;
}
.goalHeading {
  margin-bottom: 4px;
}
#input_week_style_1 {
  max-width: 400px;
  margin-bottom: 4px;
}

.therapistScoreOptions {
  display: flex;
  flex-wrap: wrap;
}
.therapistScoreOptions > label {
  margin: 0 10px 0 10px;
}

.scoreBox > button {
  max-width: 200px;
  margin-top: 15px;
}
.preTest > h4 {
  margin-bottom: 2px;
}
.testFields {
  margin: 20px 0;
}
.testFields > label {
  margin: 0 10px 0 10px;
}

.postTest {
  margin-top: 20;
}

@media only screen and (max-width: 990px) {
  .addpatientButton {
    font-size: 15px;
  }
  .tabs-container > button {
    background-color: #005689;
    border: 0px;
    color: white;
    height: 100%;
    font-size: 15px;
    min-width: 150px;
    margin: 0 2px;
  }
}

@media only screen and (max-width: 946px) {
  .patients-main-container {
    border: 0px solid saddlebrown;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
  }
  .patients-left-container {
    width: 100%;
    position: sticky;
    height: auto;
  }
  .search-add {
    flex-direction: row;
    margin-top: 5px;
  }
  .search-container {
    width: 50%;
    margin: 5px;
  }

  .addpatientButton {
    width: 50%;
    margin: 5px;
  }
  .toggle {
    display: block;
    margin: 5px;
    padding: 5px;
  }
  .patients-list-container {
    display: none;
  }

  .patients-right-container {
    margin-left: 0%;
    border: 0px solid greenyellow;
    background-color: #f3f5f9;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media only screen and (max-width: 926px) {
}

@media only screen and (max-width: 700px) {
  .patients-main-container {
    border: 0px solid saddlebrown;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
  }
  .patients-left-container {
    width: 100%;
    position: sticky;
    height: auto;
  }
  .search-add {
    flex-direction: row;
  }
  .search-container {
    width: 50%;
    margin: 5px;
  }

  .addpatientButton {
    width: 50%;
    margin: 5px;
  }
  .patients-right-container {
    margin-left: 0%;

    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .tabs-container > button {
    font-size: 12px;
    min-width: 100px;
    margin: 0 2px;
  }
}

@media only screen and (max-width: 550px) {
  .tabs-container {
    justify-content: center;
  }
  .tabs-container > button {
    font-size: 12px;
    min-width: none;
    margin: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .patients-right-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .input-container {
    display: flex;
    flex-direction: column;
  }
  .add-patient-container input {
    min-width: 400px;
    padding: 10px 0;
  }
  .recommendations-box-container {
    width: 100%;
    min-width: 100px;
  }
  .exercise-wrapper {
    margin: 20px;
    width: 100%;
    min-width: 0px;
  }
}

@media only screen and (max-width: 492px) {
  .add-patient-container form {
    display: flex;
    flex-direction: column;
    border: 0px solid saddlebrown;
    min-width: none;
    max-width: 500px;
    margin: 5px 5px 5px 5px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin: 5px 0 5px 0;
  }

  .tabs-container {
    border: 1px solid gold;
    background-color: #0277bd;
  }
  .tabs-container > button {
    font-size: 10px;
    min-width: none;
    margin: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .patients-right-container {
    margin-left: 0%;

    width: 100%;
  }
  .add-patient-container input {
    min-width: 250px;
    padding: 10px 0;
  }
  .recommendations-box-container {
    background-color: #f3f5f9;
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    width: 100%;
    min-width: 100px;
  }
}
