* {
  margin: 0;
}

.cms-main-container {
  height: 100vh;
}

.cms-header-container {
  background-color: #0277bd;
  position: fixed;
  width: 100%;
  height: 70px;
  text-align: center;
  color: white;
}
.page-container {
  padding-top: 70px;
}
