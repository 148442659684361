.footer-container {
  font-size: smaller;
  color: #464646;
  text-align: center;
  margin: 0px;
  width: 100%;
}
.footer-container > p {
  margin: 15px;
}
