* {
  margin: 0;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.main-wrapper,
.home-page-container,
.home-page-container > div,
.team-container,
.cards,
.inner-wrapper {
  display: flex;
  flex-direction: column;
}
.home-page-container {
  padding-top: 100px;
}
.home-page-container > div {
  justify-content: center;
  align-items: center;
}
.segment-1 {
  border: 0px solid sandybrown;
}
.heading {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.collage {
  margin: 10px;
}
.collage > img {
  height: auto;
  width: 100%;
  max-width: 500px;
}
.segment-1 > p {
  border: 0px solid sandybrown;
  max-width: 650px;
  margin: 10px;
  font-weight: 400;
  text-align: justify;
}
#stay-safe {
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
}
#itro-id {
  font-weight: 400;
  font-style: normal;
}
.segment-2 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.woman {
  position: absolute;
  bottom: 0px;
  left: 80%;
  width: 120px;
}
.man {
  position: absolute;
  bottom: 0px;
  right: 80%;
  width: 179px;
}
.topic-button-container {
  border: 0px solid black;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
}
.topic-button {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 15px;
  padding: 25px 5px;
  border: 0px solid white;
  background-color: #0277bd;
  color: white;
  box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 190px;
}
.topic-button:hover {
  background-color: white;
  color: #464646;
  outline: 3px solid #0277bd;
}
.segment-3 {
  background-color: #0277bd;
}
.segment-3-wrapper {
  margin: 10px;
}
.seperator {
  display: block;
  margin: 15px;
  border: 0px solid black;
}
.app-button {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 25px 5px;
  border: 0px solid white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 190px;
}
.team-container {
  flex-wrap: wrap;

  text-align: center;
}
#team-heading {
  color: white;
  font-size: 24px;
}
.team-details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cards {
  background-color: white;
  border-radius: 5px;
  width: 350px;
  margin: 10px;
}
.inner-wrapper {
  margin: 10px;
}
.member-name {
  font-weight: 600;
  font-size: 24px;
}
.designation {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}
.department {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}
.role-name {
  font-weight: 600;
  font-size: 20px;
}
.role-desc {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}
.email-heading {
  font-weight: 600;
  font-size: 20px;
}
.email-desc {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}

.funds-details {
  color: white;
  text-align: center;
  font-weight: 400;
}
.icmr-logo-container {
  background-color: aliceblue;
  width: 150px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}
.icmr-logo {
  display: block;
  max-width: 100%;
  height: auto;
}

/* error related css classes */
.errorField {
  outline: 2px solid #ba1a1a;
  border-color: #ba1a1a;
  /* font-family: FontAwesome; */
  /* content: "\f06a"; */
}

.errorMsg {
  display: none;
  color: #ba1a1a;
  font-size: small;
}

@media only screen and (max-width: 473px) {
  body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .heading {
    font-size: 20px;
  }
  #stay-safe {
    font-size: 19px;
  }
}

@media only screen and (max-width: 946px) {
  .home-page-container {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1097px) {
  .woman {
    border: 0px solid gold;
    position: absolute;
    bottom: 0px;
    left: 83%;
    width: auto;
    height: 100%;
  }
  .man {
    position: absolute;
    bottom: 0px;
    right: 80%;
    width: auto;
    height: 100%;
  }
}
