.stopfalls-main-container {
  border: 0px solid saddlebrown;
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
}
.stopfalls-left-container {
  background-color: #0277bd;
  display: flex;
  flex-direction: column;
  width: 17%;
  max-height: 100vh;
  position: fixed;
  top: 70px;
  bottom: 0px;
  overflow: auto;
}

.burger {
  display: none;
  color: white;
  margin: 5px;
}
.topics-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.backToHome {
  background-color: #004a75;
  width: 100%;
  border: 0;
  padding: 20px 0;
  font-size: 20px;
  color: white;
}
.backToHome:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}
.topics-container {
  margin: 20px 0;
}
.topics-container > span {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin: 15px 0;
  color: white;
  font-size: 21px;
  font-weight: 400;
}

.topics-container img {
  width: 20px;
  margin: 0 10px 0 0;
}
.topics-container > span:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.social-media {
  background-color: aquamarine;
  display: flex;
  justify-content: center;
}
.social-media div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.social-media img {
  height: 30px;
}

.stopfalls-right-container {
  margin-left: 17%;
  border: 0px solid greenyellow;
  width: 83%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.content-container {
  flex-grow: 1;
  width: 75%;
  max-width: 1800px;
}
.topic-heading {
  font-size: 30px;
  margin: 10px 0;
}
.topic-heading img {
  width: 20px;
  margin: 0 5px 0 0;
}
.temp-video {
  text-align: center;
  background-color: rgb(138, 138, 138);
  color: white;
}

.slide-image img {
  margin: auto;
  display: block;
  width: auto;
  max-width: 600px;
  max-height: 300px;
  height: auto;
}
.overall-text {
  margin: 20px 0 10px 0;
  font-weight: 400;
}
.sub-heading {
  background-color: #0278bd38;
  margin-top: 20px;
  border-bottom: 0px solid #0277bd;
  border-top: 0px solid #0277bd;
}
.imageAndText-container-1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 0;
}
.imageTextWrapper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin: 20px;
  width: 350px;
  height: auto;
  font-weight: 400;
}
.image-wrapper {
  margin: 10px;
}
.image-wrapper img {
  background-color: rgba(235, 235, 235, 0);
  display: block;
  max-width: 100%;
  width: 350px;
  height: auto;
  max-height: 250px;
  margin: auto;
}
.text-wrapper {
  margin: 10px 0;
}

@media only screen and (max-width: 946px) {
  .stopfalls-main-container {
    padding-top: 0px;
  }
  .stopfalls-left-container {
    background-color: #0277bd;
    width: 100%;
    height: auto;
    position: sticky;
    top: 0;
  }
  .stopfalls-right-container {
    margin-left: 0%;
    width: 100%;
    min-height: 100vh;
  }
  .burger {
    text-align: center;
    display: block;
  }
  .topics-wrapper {
    display: none;
  }
  .content-container {
    flex-grow: 1;
    width: 90%;
    max-width: 1800px;
  }
  .slide-image img {
    margin: auto;
    display: block;
    width: 100%;
    max-width: none;
    max-height: none;
    height: auto;
  }
}
