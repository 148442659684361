.input_style_1 {
  border: 1px solid #0277bd;
  min-width: 400px;
  padding: 15px;
  border-radius: 5px;
}
.button_blue {
  text-align: center;
  padding: 10px 40px 10px 40px;
  margin: 5px;
  border: 0px;
  border-radius: 5px;
  background-color: #0277bd;
  box-shadow: 0px 4px 4px #0000002c;
  color: white;
}
.button_red {
  padding: 10px 40px 10px 40px;
  margin: 5px;
  border: 0px;
  border-radius: 5px;
  background-color: #862b30;
  box-shadow: 0px 4px 4px #0000002c;
  color: white;
}

.confirm-modal {
  background-color: #d8d8d881;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.confirm-box {
  background-color: #d8d8d8;
  border: 4px solid #862b306e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  max-height: 300px;
  max-width: 700px;
}

.confirm-box p {
  text-align: center;
}

.confirm-cancel {
  display: flex;
  justify-content: center;
}

#addResultGoodd {
  background-color: rgb(32, 160, 32);
  display: none;
  width: 100%;
  color: white;
  text-align: center;
}
.addResultGoodd {
  background-color: rgb(32, 160, 32);
  display: none;
  width: 100%;
  color: white;
  text-align: center;
}
.addResultBadd {
  display: none;
  text-align: center;
  width: 100%;
  background-color: #862b30;
  color: white;
}
.addResultGood {
  background-color: rgb(32, 160, 32);
  display: none;
  width: 100%;
  color: white;
  text-align: center;
}
.addResultBad {
  display: none;
  text-align: center;
  width: 100%;
  background-color: #862b30;
  color: white;
}
.addResultGood1 {
  background-color: rgb(32, 160, 32);
  display: none;
  width: 100%;
  color: white;
  text-align: center;
}
.addResultBad1 {
  display: none;
  text-align: center;
  width: 100%;
  background-color: #862b30;
  color: white;
}
.addResultexerGood {
  background-color: rgb(32, 160, 32);
  display: none;
  width: 100%;
  color: white;
  text-align: center;
}
.addResultexerBad {
  display: none;
  text-align: center;
  width: 100%;
  background-color: #862b30;
  color: white;
}

input:disabled {
  background-color: #d1d1d1;
}

@media only screen and (max-width: 492px) {
  .input_style_1 {
    border: 1px solid #0277bd;
    min-width: none;
    padding: 15px;
    border-radius: 5px;
  }
}
